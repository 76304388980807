import { dev } from "$app/environment";
import * as Sentry from "@sentry/sveltekit";

Sentry.init({
  dsn:
    "https://0d63ca401be94759bf76d338206ae559@o4505567485558784.ingest.sentry.io/4505568184958976",

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: dev ? "development" : "production",

  // Optional: Initialize Session Replay:
  integrations: [Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 0.8,
});

export const handleError = Sentry.handleErrorWithSentry();
